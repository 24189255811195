import { createRoot } from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import {
  ComponentContextProvider,
  ElementContextProvider,
  ServiceContextProvider,
  AppSwitcher,
  CommonModule,
  initApp,
  ProductModule
} from "@ultracommerce/react-storefront/global";

import "./i18n";
import store from "./createStore";
import "./assets/theme";
import preload from "./preload";


import { CreateGuestAccount } from "./components/Account/CreateGuestAccount/CreateGuestAccount"
import { ProductMultiCartButtons } from "./modules/Product/Controls/ProductMultiCartButtons"
import { QuoteDetail } from './components/Account/AccountQuotes/QuoteDetail'

initApp(preload);

CommonModule.CreateGuestAccount = CreateGuestAccount;
CommonModule.QuoteDetail = QuoteDetail;
ProductModule.CoreControl.ProductMultiCartButtons = ProductMultiCartButtons;

createRoot(document.getElementById("app")!).render(
  <Provider store={store}>
    <Router>
      <ServiceContextProvider>
        <ElementContextProvider customComponents={{ CommonModule ,ProductModule }}>
          <ComponentContextProvider>
            <AppSwitcher />
          </ComponentContextProvider>
        </ElementContextProvider>
      </ServiceContextProvider>
    </Router>
  </Provider>
);
